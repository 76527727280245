import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from '../components/seo'
import XMR from "../images/xmr.svg"

const ExcerptsPage = ( {data , pageContext} ) => {

    const { currentPage, numPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()



    return (
        <Layout>
        <SEO title="Poetry and Writing Excerpts" description="Exerpts from poetry collections, and misc. writing from Christian Lacdael" />
        <ul className="breadcrumb">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/excerpts">Excerpts</Link></li>
        <li> { currentPage } </li>
        </ul>
        <div id="excerpts">
        {data.allMarkdownRemark.edges.map( (p,i) => <Excerpt key={i} data={p} />)}
        </div>

        <ul style={{ textAlign:"center" }} className="breadcrumb">
            {!isFirst && (
                <li><Link to={ "/excerpts/"+prevPage } rel="prev"> Previous Page </Link></li>
            )}
            {Array.from({ length: numPages }, (_, i) => (
                <li key={ "p1"+i  } ><Link key={`pagination-number${i + 1}`} to={`/excerpts/${i === 0 ? "" : i + 1}`}> {i + 1}</Link></li>
            ))}
            {!isLast && (
                <li> <Link to={ "/excerpts/"+nextPage } rel="next"> Next Page </Link></li>
            )}
        </ul>

        </Layout>
    );
}


const Excerpt = ({ data }) => (
    <div title={data.node.title}>
    <Link to={data.node.fields.slug}>
    { data.node.frontmatter.icon ?
        <Img fluid={data.node.frontmatter.icon.childImageSharp.fluid} alt={data.node.frontmatter.title}  title={data.node.frontmatter.title} /> :
        <><h3>{ data.node.frontmatter.title }</h3><div>{ data.node.excerpt }...</div></>
    }
    </Link>
    </div>
);


export const excerptsQuery = graphql`
query ExcerptQuery( $skip: Int!, $limit: Int! ) {
  allMarkdownRemark(
    filter: {
        fields: {
            slug: {
                glob: "*/excerpts/*"
            }
         }
    },
    sort: {
        order: DESC,
        fields: frontmatter___date
    },
    limit : $limit,
    skip : $skip
  ) {
    edges {
      node {
        fields {
          slug
        }
        id
        excerpt
        frontmatter {
          date(formatString: "Y-MM-DD")
          title
          icon {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
  }
}`

export default ExcerptsPage
